
export default {
  name: 'CardBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      if (this.blok.theme === 'dark') return 'primary'
      if (this.blok.theme === 'light') return 'secondary'
      return undefined
    },
  },
}
